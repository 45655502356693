import type {
  AwardPost,
  BirthdayPost,
  BlockResponseDetails,
  FlowData,
  FlowPostResponse,
  LegacyPostTypesUnion,
  MemberDetails,
  RecognitionPost,
  WorkAnniversaryPost,
} from '@assembly-web/services';
import {
  BlockResponseState,
  BlockTypes,
  LegacyPostTypes,
  MemberRole,
  MemberState,
  parsePostResponseRecipients,
  PersonSelectorSelectType,
  VisibilityTypes,
} from '@assembly-web/services';
import { defineMessages, type IntlShape } from 'react-intl';

import {
  AnniversaryIcon,
  AnonymousAvatarIcon,
  audioFileIcon,
  BirthdayIcon,
  csvFileIcon,
  docFileIcon,
  genericFileIcon,
  imageFileIcon,
  pdfFileIcon,
  presentationFileIcon,
  videoFileIcon,
  zipFileIcon,
} from '../../assets/icons';

const messages = defineMessages({
  whatToSayQn: {
    id: 'Zny4Po',
    defaultMessage: 'What do you want to say?',
  },
  whoToCelebrateQn: {
    id: '2iD0/h',
    defaultMessage: 'Who would you like to celebrate?',
  },
  coreValueQn: {
    id: 'Q8dTPA',
    defaultMessage: 'Choose a core value.',
  },
  pointsQn: {
    id: 'R0X+a3',
    defaultMessage: 'Lastly, boost your celebration with {currencyName}!',
  },
});

const GiveRecognitionFlowData: FlowData = {
  name: 'Give recognition',
  flowId: 'recognition',
  kind: '',
  icon: { kind: 'HEX_CODE', value: '1F389' },
};

const LegacyPostMap = {
  [LegacyPostTypes.Birthday]: 'Happy Birthday',
  [LegacyPostTypes.Anniversary]: 'Happy Anniversary',
};

export const getRespondentForLegacyFlowPost = (
  type: LegacyPostTypes.Birthday | LegacyPostTypes.Anniversary
): MemberDetails => ({
  memberID: '',
  email: '',
  firstName: LegacyPostMap[type],
  lastName: '',
  name: LegacyPostMap[type],
  username: '',
  pointsGiven: 0,
  totalPointsGiven: 0,
  memberState: MemberState.Active,
  role: [MemberRole.Admin],
  image: type === LegacyPostTypes.Birthday ? BirthdayIcon : AnniversaryIcon,
});

export const getRespondentForAnonymousFlowPost = (): MemberDetails => ({
  memberID: '',
  email: '',
  firstName: 'Anonymous User',
  lastName: '',
  name: 'Anonymous User',
  username: 'anonymous',
  pointsGiven: 0,
  totalPointsGiven: 0,
  memberState: MemberState.Active,
  role: [MemberRole.Admin],
  image: AnonymousAvatarIcon,
});

export const mapLegacyPostDataToPostBlocks = (
  legacyPost:
    | RecognitionPost['post']
    | BirthdayPost['post']
    | WorkAnniversaryPost['post']
    | AwardPost['post'],
  formatMessage: IntlShape['formatMessage']
) => {
  const blocks: BlockResponseDetails[] = [];
  const {
    coreValue,
    gifURL,
    message,
    messageHtml,
    pointsEach,
    taggedUsers,
    recipient,
    editedAt,
    currencyName,
    to,
    postID,
  } = legacyPost;

  const hasRecipients = Boolean(
    recipient &&
      ((recipient.everyone && recipient.everyone.count > 0) ||
        (recipient.departments && recipient.departments.length > 0) ||
        (recipient.individuals && recipient.individuals.length > 0))
  );

  if (hasRecipients || to.length) {
    const celebrateBlock: BlockResponseDetails = {
      blockId: '1',
      state: BlockResponseState.Responded,
      type: BlockTypes.PersonSelector,
      content: {
        title: formatMessage(messages.whoToCelebrateQn),
        type: BlockTypes.PersonSelector,
        selectType: PersonSelectorSelectType.SinglePerson,
        rules: { required: false, select: 'EVERYONE' },
      },
      edited: editedAt,
      response: {
        ...(recipient && hasRecipients
          ? {
              recipients: parsePostResponseRecipients(recipient, postID),
            }
          : {
              persons: to.map((member) => ({
                ...member,
                memberID: member.memberID,
                memberId: member.memberID,
                name: {
                  firstName: member.firstName,
                  lastName: member.lastName,
                },
                image: member.image ?? undefined,
              })),
            }),
      },
    };
    blocks.push(celebrateBlock);
  }

  if (coreValue) {
    const coreValueBlock: BlockResponseDetails = {
      blockId: '3',
      type: BlockTypes.Dropdown,
      state: BlockResponseState.Responded,
      edited: editedAt,
      content: {
        title: formatMessage(messages.coreValueQn),
        type: BlockTypes.Dropdown,
        options: [],
        rules: { required: false, limit: { exact: 1 } },
      },
      response: {
        value: [
          {
            value: coreValue,
            id: coreValue,
          },
        ],
      },
    };

    blocks.push(coreValueBlock);
  }

  if (messageHtml || message) {
    const openEndedBlock: BlockResponseDetails = {
      blockId: '3',
      type: BlockTypes.OpenEnded,
      state: BlockResponseState.Responded,
      edited: editedAt,
      content: {
        title: formatMessage(messages.whatToSayQn),
        type: BlockTypes.OpenEnded,
        rules: {
          required: false,
          allowedMedia: [],
          fileType: [],
        },
      },
      response: {
        ...(messageHtml
          ? { valueHtml: messageHtml, taggedUsers }
          : {
              value: message,
              gifUrl: gifURL,
              mentions: taggedUsers.map((member) => ({
                ...member,
                memberID: member.memberID,
                memberId: member.memberID,
                name: {
                  firstName: member.firstName,
                  lastName: member.lastName,
                },
                image: member.image ?? undefined,
              })),
            }),
        files: legacyPost.attachments ?? [],
      },
    };

    blocks.push(openEndedBlock);
  }

  if (pointsEach) {
    const coreValueBlock: BlockResponseDetails = {
      blockId: '3',
      type: BlockTypes.GivePointsStack,
      state: BlockResponseState.Responded,
      edited: editedAt,
      content: {
        title: formatMessage(messages.pointsQn, {
          currencyName,
        }),
        type: BlockTypes.GivePointsStack,
        rules: {
          required: false,
          hidePoints: false,
          points: { noLimit: true },
        },
      },
      response: {
        value: pointsEach,
      },
    };

    blocks.push(coreValueBlock);
  }

  return blocks;
};

export function convertRecognitionPostToFlowPostFormat(
  recognitionPost: RecognitionPost['post'],
  formatMessage: IntlShape['formatMessage']
): FlowPostResponse {
  const {
    kind,
    from,
    postID,
    createdAt,
    isPrivate,
    reactions,
    commentsCount,
    hasReplyDraft,
    isMuted,
    type,
    editedAt,
  } = recognitionPost;

  return {
    hasReplyDraft,
    respondent: from,
    reactions,
    responseId: postID,
    instanceId: '',
    commentsCount: commentsCount,
    createdAt,
    kind,
    isPrivate,
    responses: mapLegacyPostDataToPostBlocks(recognitionPost, formatMessage),
    flow: GiveRecognitionFlowData,
    visibility: isPrivate ? VisibilityTypes.Private : VisibilityTypes.Everyone,
    canDelete: false,
    canEdit: false,
    isBoostAllowed: true,
    postType: type,
    edited: editedAt,
    ...(isMuted && { isMuted }),
  };
}

export function convertAwardPostDataToFlowPostFormat(
  awardPost: AwardPost['post'],
  formatMessage: IntlShape['formatMessage']
): FlowPostResponse {
  const {
    postID,
    commentsCount,
    createdAt,
    kind,
    isPrivate,
    reactions,
    hasReplyDraft,
    isMuted,
    from,
    type,
    editedAt,
    coreValue,
    pointsEach,
  } = awardPost;

  return {
    hasReplyDraft,
    respondent: from,
    reactions,
    responseId: postID,
    instanceId: '',
    commentsCount,
    createdAt,
    kind,
    isPrivate,
    responses: mapLegacyPostDataToPostBlocks(awardPost, formatMessage),
    flow: GiveRecognitionFlowData,
    visibility: isPrivate ? VisibilityTypes.Private : VisibilityTypes.Everyone,
    canDelete: false,
    canEdit: false,
    isBoostAllowed: true,
    postType: type,
    edited: editedAt,
    coreValue,
    points: pointsEach,
    award: awardPost.award,
    ...(isMuted && { isMuted }),
  };
}

export function convertPostDataToFlowPostFormat(
  legacyPost: LegacyPostTypesUnion['post'],
  formatMessage: IntlShape['formatMessage']
): FlowPostResponse {
  if (legacyPost.type === LegacyPostTypes.Birthday) {
    return convertBirthdayAndAnniversaryPostToFlowPostFormat(
      legacyPost,
      formatMessage
    );
  }

  if (legacyPost.type === LegacyPostTypes.Anniversary) {
    return convertBirthdayAndAnniversaryPostToFlowPostFormat(
      legacyPost,
      formatMessage
    );
  }

  if (legacyPost.type === LegacyPostTypes.Award) {
    return convertAwardPostDataToFlowPostFormat(legacyPost, formatMessage);
  }

  return convertRecognitionPostToFlowPostFormat(legacyPost, formatMessage);
}

export function convertBirthdayAndAnniversaryPostToFlowPostFormat(
  legacyPost: BirthdayPost['post'] | WorkAnniversaryPost['post'],
  formatMessage: IntlShape['formatMessage']
): FlowPostResponse {
  const {
    postID,
    commentsCount,
    createdAt,
    kind,
    isPrivate,
    type,
    reactions,
    hasReplyDraft,
    isMuted,
    editedAt,
  } = legacyPost;

  return {
    hasReplyDraft,
    respondent: getRespondentForLegacyFlowPost(type),
    reactions,
    responseId: postID,
    instanceId: '',
    commentsCount,
    createdAt,
    kind,
    isPrivate,
    responses: mapLegacyPostDataToPostBlocks(legacyPost, formatMessage),
    flow: GiveRecognitionFlowData,
    visibility: VisibilityTypes.Everyone,
    canDelete: false,
    canEdit: false,
    isBoostAllowed: true,
    postType: type,
    edited: editedAt,
    ...(isMuted && { isMuted }),
  };
}

export function getFileIconForFileCard(file: string) {
  switch (file) {
    case 'doc':
    case 'docx':
      return docFileIcon;

    case 'bmp':
    case 'gif':
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'svg':
      return imageFileIcon;

    case 'pps':
    case 'ppsx':
    case 'ppt':
    case 'pptx':
      return presentationFileIcon;

    case 'pdf':
      return pdfFileIcon;

    case 'csv':
    case 'xls':
    case 'xlsx':
      return csvFileIcon;

    case 'ogg':
    case 'm4a':
    case 'mp3':
    case 'wav':
      return audioFileIcon;

    case '3d2':
    case '3gp':
    case 'avi':
    case 'mov':
    case 'mp4':
    case 'mpg':
    case 'ogv':
      return videoFileIcon;

    case 'zip':
      return zipFileIcon;

    default:
      return genericFileIcon;
  }
}
